#root {
  height: auto;
  min-height: 100vh;
  display: flex;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;

  background-color: transparent;
  color: white;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.powered-by {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-delete {
  color: rgba(0, 0, 0, 0);
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(0, 0, 0, 0);
  visibility: hidden;
}